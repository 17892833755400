import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "icon"
};
const _hoisted_2 = {
  class: "content NewsDeta"
};
const _hoisted_3 = {
  class: "title"
};
const _hoisted_4 = {
  class: "acea-row row-between-wrapper times"
};
const _hoisted_5 = ["innerHTML"];
import { router } from "@/router";
import { useRoute } from "vue-router";
import { getNewsDetail } from "@/http";
import { computed, ref } from "vue";
import { useUserStore } from "@/stores/user";
export default {
  __name: 'NewsDetails',
  props: {
    id: String
  },
  setup(__props) {
    const props = __props;
    const route = useRoute();
    const id = route.params.id;
    console.log(id);
    let Datas = ref({});
    const _getNewsDetail = () => {
      getNewsDetail({
        id: id
      }).then(res => {
        console.log(res);
        Datas.value = res.data;
      });
    };
    _getNewsDetail();
    const userStore = useUserStore();
    const lang = computed(() => userStore.lang);
    const contentKey = computed(() => {
      return {
        'en_US': 'content',
        'fr_FR': 'content-fr',
        'es_ES': 'content-sp',
        'it_IT': 'content-it',
        'vi': 'content-vt',
        'en_TH': 'content-th'
      }[lang.value];
    });
    const back = () => {
      router.go(-1);
    };
    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
        class: "backLeft acea-row row-left",
        onClick: back
      }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_icon, {
        name: "down"
      })]), _createElementVNode("span", null, _toDisplayString(_ctx.$t('Return')), 1)]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_unref(Datas).title), 1), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", null, _toDisplayString(_unref(Datas).created_at), 1), _createElementVNode("div", null, _toDisplayString(_unref(Datas).author), 1)]), _createElementVNode("div", {
        class: "center",
        innerHTML: _unref(Datas).content
      }, null, 8, _hoisted_5)])], 64);
    };
  }
};